import { bootstrapEquansApplication } from '@equans/components';

import { AppComponent } from './app/app.component';
import { APP_CONFIG } from './app/app.config';
import { DEVEXTREME_LICENSE_KEY } from './dx-license-key';

if (navigator.userAgent.includes('iPhone')) {
  document.querySelector('[name=viewport]')?.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
}

bootstrapEquansApplication(AppComponent, APP_CONFIG, { licenseKey: DEVEXTREME_LICENSE_KEY })
  // eslint-disable-next-line no-console,@typescript-eslint/use-unknown-in-catch-callback-variable
  .catch(console.error);
