import { inject } from '@angular/core';
import { NotificationService } from '@equans/components';
import { LoggerService } from '@equans/core';
import { createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';

import { ErrorActions } from './error.actions';
import { injectActions } from './inject';

export const handleZodError$ = createEffect(
  (actions$ = injectActions(), notificationService = inject(NotificationService), loggerService = inject(LoggerService)) => {
    return actions$.pipe(
      ofType(ErrorActions.zodError),
      tap(({ errors, message, notificationMessage }) => {
        loggerService.error(`[Zod Error] ${message}`, errors);
        notificationService.notify(notificationMessage, 'error', 5000);
      })
    );
  },
  { functional: true, dispatch: false }
);

export const handleHttpError$ = createEffect(
  (actions$ = injectActions(), notificationService = inject(NotificationService), loggerService = inject(LoggerService)) => {
    return actions$.pipe(
      ofType(ErrorActions.httpError),
      tap(({ message, status, statusText, notificationMessage }) => {
        loggerService.error(`[HTTP Error] ${message}`, { status, statusText });
        notificationService.notify(notificationMessage, 'error', 5000);
      })
    );
  },
  { functional: true, dispatch: false }
);

export const handleGenericError$ = createEffect(
  (actions$ = injectActions(), notificationService = inject(NotificationService), loggerService = inject(LoggerService)) => {
    return actions$.pipe(
      ofType(ErrorActions.genericError),
      tap(({ message, stack, notificationMessage }) => {
        loggerService.error(`[Generic Error] ${message}`, stack);
        notificationService.notify(notificationMessage, 'error', 5000);
      })
    );
  },
  { functional: true, dispatch: false }
);
